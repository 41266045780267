import React from 'react'

const test = () => {
    return (
        <div>
            Testing...
        </div>
    )
}

export default test
